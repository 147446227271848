import React, { useEffect, useRef } from 'react';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';
import Button, { TypeButton } from '@/components/NewButton';
import AttentionIcon from '@/components/Icon/Attention';
import CancelClassModal, { CancelClassModalRef } from './CancelClassModal';
import { useTranslation } from 'next-i18next';

interface CancelLiveProps {
  subject: string;
  courseScheduleId: string;
  source: string;
}
const CancelLive = (props: CancelLiveProps) => {
  const { subject, source, courseScheduleId } = props;
  const { t } = useTranslation('curriculum');
  const { traceEventWithAccount } = useTraceEventWithAccount();
  const cancelClassModalRef = useRef<CancelClassModalRef>(null);

  useEffect(() => {
    traceEventWithAccount({
      _event: 'O_TeacherAbsence_ButtonExpose',
      courseScheduleId,
      subject,
      source,
    });
  }, []);

  return (
    <>
      <Button
        key="liveCancelBtn"
        type={TypeButton.RED}
        text={
          <div className="flex flex-row items-center leading-[18px]">
            <AttentionIcon />
            <span className="ml-[4px]">{t('直播课取消')}</span>
          </div>
        }
        onClick={() => {
          traceEventWithAccount({
            _event: 'O_TeacherAbsence_ButtonClick',
            subject: subject,
            courseScheduleId: courseScheduleId,
            source,
          });
          cancelClassModalRef.current?.show();
        }}
      />
      <CancelClassModal
        ref={cancelClassModalRef}
        subject={subject}
        courseScheduleId={courseScheduleId}
        source={source}
      />
    </>
  );
};

export default CancelLive;
