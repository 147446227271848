import { Mask } from '@/components';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';
import { useTranslation } from 'next-i18next';
import { useImperativeHandle, forwardRef, useState, Ref } from 'react';

// 定义 props 的类型
interface CancelClassModalProps {
  subject: string;
  courseScheduleId: string;
  source: string;
}

// 定义 ref 的类型
export interface CancelClassModalRef {
  show: () => void;
  hide: () => void;
}

const CancelClassModal = ({ subject, courseScheduleId, source }: any, ref: Ref<CancelClassModalRef>) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation('curriculum');
  const { traceEventWithAccount } = useTraceEventWithAccount();

  useImperativeHandle(ref, () => ({
    show: () => {
      traceEventWithAccount({
        _event: 'O_TeacherAbsence_TipeExpose',
        subject: subject,
        courseScheduleId: courseScheduleId,
        source,
      });
      setIsVisible(true);
    },
    hide: () => {
      traceEventWithAccount({
        _event: 'O_TeacherAbsence_TipeClick',
        subject: subject,
        courseScheduleId: courseScheduleId,
        source,
      });
      setIsVisible(false);
    },
  }));

  return (
    <>
      {isVisible && (
        <Mask className="flex items-center justify-center">
          <div className="h-auto w-[292px] rounded-3xl bg-white p-6">
            <header className="text-center text-[16px] font-semibold leading-[24px] text-[#1B1F4D]">
              {t('课程取消通知')}
            </header>
            <section className="mt-[8px] text-center text-[14px] font-normal leading-[20px] text-[#8D8FA6]">
              {t('课程取消通知-内容')}
            </section>
            <div
              className="mt-[24px] box-border w-full cursor-pointer rounded-3xl bg-[#FF5353] px-[20px] py-[9px] text-center text-[16px] text-white"
              onClick={() => {
                traceEventWithAccount({
                  _event: 'O_TeacherAbsence_TipeClick',
                  subject: subject,
                  courseScheduleId: courseScheduleId,
                  source,
                });
                setIsVisible(false);
              }}
            >
              {t('课程取消通知-我已知晓')}
            </div>
          </div>
        </Mask>
      )}
    </>
  );
};

export default forwardRef<CancelClassModalRef, CancelClassModalProps>(CancelClassModal);
