import { useEffect, useCallback } from 'react';
import { ONLINE } from '@/utils/constants';
import useThrottle from './useThrottle';

/**
 * 监听网络连接和页面可见性变化的 Hook
 * @param callback 当网络连接或页面变为可见时要执行的回调函数
 */
const useNetworkAndVisibility = (callback: () => void) => {
  // 使用节流包装后的处理函数，10秒内只执行一次
  const throttledCallback = useThrottle(callback, 10000);

  const handleOnlineOrVisible = useCallback(() => {
    if (document.visibilityState === 'visible' || navigator.onLine) {
      throttledCallback();
    }
  }, [throttledCallback]);

  useEffect(() => {
    // 监听网络连接
    window.addEventListener(ONLINE, handleOnlineOrVisible);
    // window.addEventListener(OFFLINE, handleOnlineOrVisible);
    // 监听页面可见性
    document.addEventListener('visibilitychange', handleOnlineOrVisible);

    // // 初始检查
    // if (document.visibilityState === 'visible' || navigator.onLine) {
    //   handleOnlineOrVisible();
    // }

    return () => {
      window.removeEventListener(ONLINE, handleOnlineOrVisible);
      // window.removeEventListener(OFFLINE, handleOnlineOrVisible);
      document.removeEventListener('visibilitychange', handleOnlineOrVisible);
    };
  }, [handleOnlineOrVisible]);
};

export default useNetworkAndVisibility;
