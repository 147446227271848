const Attention = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <g clipPath="url(#clip0_12959_65530)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.00003 11.1429C8.84036 11.1429 11.1429 8.84036 11.1429 6.00003C11.1429 3.15971 8.84036 0.857178 6.00003 0.857178C3.15971 0.857178 0.857178 3.15971 0.857178 6.00003C0.857178 8.84036 3.15971 11.1429 6.00003 11.1429ZM6.64284 3.04287C6.64284 2.6847 6.35814 2.40001 5.99998 2.40001C5.64182 2.40001 5.35712 2.6847 5.35712 3.04287V6.25715C5.35712 6.61532 5.64182 6.90001 5.99998 6.90001C6.35814 6.90001 6.64284 6.61532 6.64284 6.25715V3.04287ZM5.99996 7.58577C6.42425 7.58577 6.77139 7.93291 6.77139 8.35719C6.77139 8.78148 6.42425 9.12862 5.99996 9.12862C5.57567 9.12862 5.22853 8.78148 5.22853 8.35719C5.22853 7.93291 5.57567 7.58577 5.99996 7.58577Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12959_65530">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Attention;
