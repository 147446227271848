import { useCallback, useRef } from 'react';

/**
 * 节流 hook
 * @param fn 需要节流的函数
 * @param delay 节流时间（毫秒）
 */
const useThrottle = <T extends (...args: any[]) => any>(fn: T, delay: number) => {
  const lastTime = useRef<number>(0);

  return useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now();
      if (now - lastTime.current >= delay) {
        fn(...args);
        lastTime.current = now;
      }
    },
    [fn, delay],
  );
};

export default useThrottle;
