import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { getNoticeInfo } from '@/api/home';
import useReportForGs2User from '@/utils/hooks/useReportForGs2User';
import { traceEvent } from '@wk/wk-gatherer';
import { useUpdateEffect } from 'react-use';
import { GetNoticeInfoRes } from '@/api/types/home';

import styles from './style.module.scss';

const Notice = () => {
  const { t } = useTranslation('home');
  const reportGs2Params = useReportForGs2User();
  const [noticeInfo, setNoticeInfo] = useState<GetNoticeInfoRes | null>(null);

  useEffect(() => {
    getNoticeInfo().then((res) => {
      setNoticeInfo(res ?? null);
    });
  }, []);

  useUpdateEffect(() => {
    if (Object.keys(reportGs2Params).length === 0) {
      return;
    }
    traceEvent({
      data: {
        _event: 'U_HP_NoticeExpose',
        ...reportGs2Params,
      },
    });
  }, [reportGs2Params]);

  return noticeInfo?.displayNotice ? (
    <div className={styles.notice}>
      <section className={styles.noticeIcon}>
        <Image src="/imgs/pc/home/notice-icon.svg" width={28} height={28} alt="" />
      </section>
      <section className={styles.noticeMain}>
        <span className={styles.title}>{noticeInfo.title}：</span>
        <span className={styles.content}>{noticeInfo.message}</span>
      </section>
      <section
        className={styles.noticeBtn}
        role="button"
        onClick={() => {
          traceEvent({
            data: {
              _event: 'U_HP_Notice_ViewDetailsClick',
              ...reportGs2Params,
            },
          });
          window.open(noticeInfo.link);
        }}
      >
        <span className={styles.text}>{t('系统通知-查看详情')}</span>
        <Image src="/imgs/pc/home/right-arrow-icon.svg" width={12} height={12} alt="" />
      </section>
    </div>
  ) : null;
};

export default Notice;
