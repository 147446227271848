// 直播取消的来源
export const LiveCancellationSource = {
  HOME: 'HOME',
  CALENDAR: 'CALENDAR',
  MY_COURSES: 'MY_COURSES',
};

export const CHANGE_TEACHER_FAIL = 'failed';

export const isTeacherNotComing = (params: {
  key: 'teacherNotComing' | 'changeTeacher';
  value: boolean | string;
}): boolean => {
  const { key, value } = params || {};
  if (key === 'teacherNotComing') return Boolean(value);
  if (key === 'changeTeacher') return value === CHANGE_TEACHER_FAIL;
  return false;
};
