export interface ClassinUrlReq {
  liveUrl: string;
}

export interface ClassinUrlRes {}

export interface CheckLeaveReq {
  courseScheduleId: string;
}

export interface CheckLeaveRes {
  message: string;
  success: boolean;
  urgent: boolean;
}

export interface LeaveApplyReq {
  courseScheduleId: string;
  reason: string;
  urgent?: boolean;
  acceptReschedule?: boolean;
}

export interface LeaveApplyRes {}

export interface PlaybackVideoRes {
  absenteeism: boolean;
  playbackVideos: {
    duration: number;
    order: number;
    size: number;
    url: string;
  }[];
}

export enum PlayBackStatusEnum {
  /**
   * 已过期
   */
  EXPIRED = 'EXPIRED',
  /**
   * 已生成
   */
  GENERATED = 'GENERATED',
  /**
   * 未生成
   */
  UNGENERATED = 'UNGENERATED',
  /**
   *
   */
}

export type PlaybackStatusV2 = 'EXPIRED' | 'GENERATED' | 'UNGENERATED' | 'PRIVACY_UNGENERATED';

export interface ScheduleDetailRes {
  absenteeism: boolean;
  platform?: string;
  uuid: string;
  liveUrl: string;
  scheduleName: string;
  classType: 'GROUP' | 'PRIVATE';
  courseSubject?: 'MATH' | 'CHINESE' | 'ENGLISH';
  classInId: string;
  period?: string;
  teacher: {
    fullName: string;
    wkHeadImageUrl: string;
    contentUrl: string;
    data: {
      wkHeadImageUrl: string;
      contentUrl: string;
    };
  };
  duration: number;
  timeOffset: number;
  homework: Record<string, string>;
  startDateTime: string;
  endDateTime: string;
  data: {
    class_in_data: {
      live_info: {
        HLS: string;
      };
    };
    changeTeacher?: string;
  };
  courseSectionRespDTO: {
    uuid: string;
    sectionName: string;
    data: {
      subjectType: string;
      sectionNameStudent: string;
      level1: string;
    };
    homework: any;
    course: {
      subject: 'MATH' | 'CHINESE' | 'ENGLISH' | undefined;
      courseType: 'STANDARD' | 'TRIAL' | 'PUBLIC';
    };
    stageDisplay: string;
    sectionNameDisplay: string;
    sectionNameDisplayEn: string;
  };
  playbackStatus: PlayBackStatusEnum;
  playbackStatusV2: PlaybackStatusV2;
  cancelButton?: boolean;
}

export enum StatusEnum {
  NORMAL = 'NORMAL',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  LEAVE_APPLIED = 'LEAVE_APPLIED',
  ABSENT = 'ABSENT',
}

export enum ReviewStatus {
  NONE_SUBJECTIVE = 'NONE_SUBJECTIVE',
  NOT_REVIEW = 'NOT_REVIEW',
  REVIEWED = 'REVIEWED',
  REMIND = 'REMIND',
}

export interface LinkComplete {
  linkId: string;
  linkComplete: boolean;
}
export interface LessonDetailRes {
  courseCommentDomainUrl: string;
  courseEnd: boolean;
  courseSchedule: ScheduleDetailRes;
  courseScheduleId: string;
  customData: any;
  hasCourseComment: boolean;
  homeworkCompleted: boolean;
  homeworkDomainUrl: string;
  leveledReaders: boolean;
  monitorEnd: boolean;
  preparationCompleted: boolean;
  preparationEnabled: boolean;
  published: boolean;
  reviewed: ReviewStatus;
  scheduleStatus: StatusEnum;
  status: StatusEnum;
  uuid: string;
  showHomework: boolean;
  hasTeachingMaterials: boolean;
  showCourseCommentButton?: boolean; // 课程评价按钮显示隐藏判断
  showLeaveButton?: boolean; // 请假按钮显示隐藏判断
  showMonitorButton?: boolean; // 监课按钮显示隐藏判断
  showStudyReportButton?: boolean; // 学习报告按钮显示隐藏判断
  showTrailStudyReportButton?: boolean; // 体验课学习报告按钮显示隐藏判断
  trial: boolean;
  homeworkLinkCompleteList: LinkComplete[];
  canAdjust?: boolean;
  canCancel?: boolean;
  canLeave?: boolean;
  leaveId?: string;
  showAdjustCard?: boolean;
  showInClass?: boolean;
}
